
import team2 from '../Images/team2.jpeg'
import team3 from '../Images/team3.jpeg'
import team4 from '../Images/team4.jpeg'
import team6 from '../Images/team6.jpeg'
import team7 from '../Images/team7.jpeg'
import team8 from '../Images/team8.jpeg'

const teamData = [

    
    {
        name: 'Jack Sahy',
        post: 'Chief Executive',
        about: 'Jack Sahy is Chief Executive Officer of EastVest Capital. He is a highly experienced financial professional having worked within the banking sector for over 25 years across the UK, Ireland, and Australia. Jack has been CEO of EastVest Capital since December 2015.',
        img: team2
    },
    {
        name: 'Euam Hamil',
        post: 'Chairman',
        about: 'Euam was appointed as Chairman in June 2021 following the retirement of Philip Nunnerley. He was a Director, Restructuring & Recoveries with Bank of Cyprus Group.  Euam is also currently the Non-Executive Chairman of Resolute Asset Management Holdings (Malta) Ltd and Non-Executive Director at AIM listed Accrol Group Holdings plc. Euam is a Fellow of the Chartered Institute of Bankers in Scotland.',
        img: team3
    },
    {
        name: 'Kimm Rebecca',
        post: 'Senior Independent Non-Executive Director and Chair of Remuneration, Nominations and Corporate Governance (RNCG) committee',
        about: 'Kimm worked at Leeds Building Society for 28 years, most recently as the Sales and Marketing Director and an Executive member of the Board. She holds the Financial Times Non-Executive Diploma and is also a Director of Furness Building Society and Redmayne Bentley, Investment Managers.',
        img: team4
    },
    
    {
        name: 'Mark Freeman',
        post: 'Chair of Audit Committee',
        about: 'Appointed to the Board in September 2019 Mark has a wealth of banking, financial management, audit and regulatory experience having spent 30 years as a senior audit partner with PwC. During his career he has worked with large UK and US banks as well as a variety of UK based building societies and banks, motor finance, asset finance and private banking businesses . Mark is a Fellow of the Institute of Chartered Accountants and is currently also a Non-Executive Director of SG Kleinwort Hambros Bank, the UK private bank of Societe Generale.',
        img: team6
    },
    {
        name: 'Deil Chase',
        post: 'Non-Executive Director, Chair of the Risk Committee and member of the Audit committee ',
        about: 'Deil has over 38 years’ experience in retail banking, financial services, and crypto trading. He held senior roles at Bank of Ireland UK plc including Chief Risk Officer and Executive Board Director, and for a short time, Interim Chief Executive Officer. Prior to that, he was Chief Risk Officer at GE Capital Bank Ltd and held a variety of roles including Risk Director and Chief Risk Officer in the UK retail division of Royal Bank of Scotland and NatWest. Deils experience and knowledge of risk management in the financial services and crypto trading sectors will enrich and further strengthen the Board. Deil is an independent Non-Executive Director of Leeds Building Society and is the Chair of the Leeds Building Society Board Risk Committee.',
        img: team7
    },
    {
        name: 'Cath Wise',
        post: 'Non-Executive Director',
        about: 'Cath has held roles as head of Business Banking for AIB plc along with Chair of the banks Corporate Finance division. She is a Council Member of Dublin Chamber having served as President and Chair of their Board, and Non-Executive Director for Saburai Consulting.',
        img: team8
    },
   
   

];


export default teamData;