import React from "react";
import Slider from "react-slick";
import "./Slider.css";
import "./Slider-theme.css";
import { Container } from "react-bootstrap";
import SimpleBtn from "../Buttons/SimpleBtn";





function SimpleSlider({slideData}) {
  const settings = {
    dots: false,
    autoplaySpeed: 6000,
    autoplay: true,
    infinite: true,
    speed: 700,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="relative">
      <Slider {...settings}>
        {
          slideData.map((info, index) => (
            <div key={index} className="slide__container ">
                        <div className="slide-content bg-secondary-500 text-slate-100" >
                                   <Container fluid className=" lg:pl-14">
                                        
                                    <div className="grid grid-cols-1 md:grid-cols-2 ">
                                        <div className="self-center w-full px-2 py-5">
                                          <h1 className="text-slate-100 text-xl font-semibold">{info.header2}</h1>
                                            <h2 className="text-4xl font-bold py-3 text-secondary-700">{info.header1}</h2>
                      
                                            <p className="text-base pb-3">{info.bodyText}</p>
                                            <SimpleBtn  text='Register' classname='text-white bg-green-500 ' link='/auth/register'/>
                                            <SimpleBtn  text='Invest Now' classname='text-white bg-Primary-500 ml-3' link='/dashboard/invest'/>
                                            
                                        </div>
                                        <div className="w-full md:fit-content">
                                        <img className="w-full h-full md:rounded-l-full" src={info.bgImg} alt="slide pic" />
                                        </div>
                                    </div>
                                    
                                   </Container>
                        </div>
                    </div>
          ))
        }
        
      </Slider>
    
    </div>
  );
}

export default SimpleSlider;