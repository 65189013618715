import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import faq from '../Assets/Images/faq.jpeg'
import faq1 from '../Assets/Images/faq1.jpeg'
import PageShowcase from '../Components/Sections/PageShowcase';
import Headings from '../Components/Headings/Headings';
import items from '../Assets/Datas/faqs';

const ToggleAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
        <PageShowcase  bgImg={faq1} header='Welcome to our FAQ section!' text=' Here you willl find answers to the most common questions about our services. If you need further assistance, please dont hesitate to contact us.' btnText='Contact Us' link='/contact'/>

        <Container className="py-5">
      <Headings header2='EastVest' header1='Frequently Asked Questions'/>
      <div className="grid cols-1 md:grid-cols-2 gap-3">
      <div className="space-y-4">
        {items.map((item, index) => (
          <div key={index} className="bg-gray-100 rounded-lg overflow-hidden">
            <button
              onClick={() => toggleAccordion(index)}
              className="flex justify-between items-center w-full p-4 focus:outline-none"
            >
              <span className="text-lg font-medium">{item.question}</span>
              <svg
                className={`w-6 h-6 transition-transform transform ${
                  activeIndex === index ? 'rotate-180' : ''
                }`}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div
              className={`overflow-hidden transition-all ${
                activeIndex === index ? 'max-h-full py-4 opacity-100' : 'max-h-0 py-0 opacity-0'
              }`}
            >
              <p className="px-4">{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full">
        <img className='w-full  object-contain' src={faq} alt="faq-img" />
      </div>
      </div>
    </Container>
    
    </>
    
  );
};

export default ToggleAccordion;
