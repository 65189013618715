import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const PageShowcase = ({header, text, btnText, clas, bgImg, link}) => {
  return (
    <section className='bg-secondary-500 text-slate-100'>
       <Container fluid className="md:pl-">
                                        
                                    <div className="grid grid-cols-1 md:grid-cols-2 ">
                                        <div className="self-center w-full md:w-3/4 py-4 px-2 md:px-0">
                                            <h1 className="text-3xl font-bold py-3 text-secondary-700">{header}</h1>
                                            <p className="text-base pb-3">{text}</p>
                                            
                                            <Link to={link}><button className={`py-3 px-2 text-sm bg-secondary-700 text-secondary-500  rounded-lg uppercase hover:opacity-95 ${clas}` } >{btnText} <span className='pl-3'>&#62;</span></button></Link>
                                        </div>
                                        <div className="w-full md:fit-content">
                                        <img className="w-full h-full" src={bgImg} alt="slide pic" />
                                        </div>
                                    </div>
                                    
                                   </Container>
    </section>
  )
}

export default PageShowcase
