import React from 'react'
import StarIcon from '@mui/icons-material/Star';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupIcon from '@mui/icons-material/Group';
import { Container } from 'react-bootstrap';
import Headings from '../Headings/Headings';
import { grey} from '@mui/material/colors';
import whyus from '../../Assets/Images/whyus.jpeg'
import CounterSection from './Counter';


const WhyUs = () => {
  return (
    <div className='py-4 px-2'>
        <Container className='py-4 '>
            <div className='px-2 md:px-5 py-2 md-py-4'>
                <Headings header2='WHY CHOOSE US' header1='We Are Here To Improve Digital Investment' claas1='text-secondary-500'/>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className='flex flex-col gap-5'>
                    <div >
                        <div className='w-16 h-16 bg-Primary-500 rounded-sm flex justify-center items-center'><StarIcon style={{color: grey[100]}} /></div>
                        <h4 className='text-xl text-secondary-600 font-bold py-2'>One Of The Best</h4>
                        <p className='text-base text-secondary-400'>We remain the best at what we do and deliver nothing less than perfect</p>
                    </div>
                    <div>
                        <div className='w-16 h-16 bg-Primary-500 rounded-sm flex justify-center items-center'><EmojiEventsIcon style={{color: grey[100]}} /></div>
                        <h4 className='text-xl text-secondary-600 font-bold py-2'>Highly Recommended</h4>
                        <p className='text-base text-secondary-400'>We remain the choice of many and never fail our investors</p>
                    </div>
                </div>
                <div className='h-full w-full'><img className='h-full w-full object-contain' src={whyus} alt="why-choose-us" /></div>
                <div className='flex flex-col gap-5'>
                    <div >
                        <div className='w-16 h-16 bg-Primary-500 rounded-sm flex justify-center items-center'><GroupIcon style={{color: grey[100]}} /></div>
                        <h4 className='text-xl text-secondary-600 font-bold py-2'>Many Active Investors</h4>
                        <p className='text-base text-secondary-400'>More and more investors trust and join us daily</p>
                    </div>
                    <div>
                        <div className='w-16 h-16 bg-Primary-500 rounded-sm flex justify-center items-center'><PhoneIcon style={{color: grey[100]}} /></div>
                        <h4 className='text-xl text-secondary-600 font-bold py-2'>24/7 Support</h4>
                        <p className='text-base text-secondary-400'>We are always ready to help and make things work</p>
                    </div>
                </div>
            </div>
            
        </Container>
        <CounterSection />
    </div>
  )
}

export default WhyUs
