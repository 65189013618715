import React from 'react'
import about from '../Assets/Images/about1.jpeg'
import { Container } from 'react-bootstrap'
import Headings from '../Components/Headings/Headings'
import WhyUs from '../Components/Sections/WhyUs'
import Team from '../Components/Team'
import PageShowcase from '../Components/Sections/PageShowcase'
import { Helmet } from "react-helmet-async"

const AboutUs = () => {
  return (
    <>
    <Helmet>
        <title>About</title>
        <meta  name='description' content='Welcome to Eastvest Capital, where innovation meets investment excellence.'/>
        <link rel="canonical" href="https://eastvestcapital.com/about" />
      </Helmet>
        <PageShowcase bgImg={about} header='Innovation meets investment excellence.' text='As a premier investment company, Eastvest Capital specializes in leveraging cutting-edge technologies to analyze and trade cryptocurrencies, while also offering strategic investments in real estate.' btnText='Contact Us' link='/contact'/>
        <Container className='py-5'>
                <div className='px-2 md:px-5 py-2 md-py-4'>
                    <Headings header2='Who We Are' headerP='Welcome to Eastvest Capital, where innovation meets investment excellence. As a premier investment company, Eastvest Capital specializes in leveraging cutting-edge technologies to analyze and trade cryptocurrencies, while also offering strategic investments in real estate.
                    At Eastvest Capital, we pride ourselves on our team of expert traders who navigate the dynamic world of cryptocurrency markets. Our traders utilize sophisticated algorithms and data analytics to make informed decisions, maximizing profits and minimizing risks for our valued investors.
                    ' headerP2='In addition to our cryptocurrency expertise, Eastvest Capital provides opportunities for investment in real estate, a cornerstone of stable and lucrative asset growth. We carefully select real estate projects with high potential returns, ensuring diversification and long-term value for our clients portfolios. One of the unique features of Eastvest Capital is our daily profit-sharing system. When you invest with us, your chosen investment package earns you a percentage of profits added to your wallet daily. This transparent and rewarding system allows our clients to see tangible returns on their investments regularly.' header1='Empowering Your Financial Future with Eastvest Capital' claas1='text-secondary-500'/>
                </div> <br />
                <p className='text-center'>At Eastvest Capital, we harness the expertise of seasoned traders and cutting-edge technologies to navigate the volatile cryptocurrency markets. Our team's deep understanding of market trends and advanced analytical tools enable us to execute strategic trades with precision, maximizing returns for our clients.
                Whether you are new to investing or a seasoned investor, Eastvest Capital offers personalized investment solutions tailored to your financial goals. Join us today and embark on a journey towards financial success with Eastvest Capital. Start investing confidently, backed by expertise, technology, and a commitment to your prosperity.</p>
                
                <WhyUs />
                <Team />
        </Container> 
    </>
  )
}

export default AboutUs
