import React, { useState, useEffect } from 'react';
import { FormGroup, Form } from 'react-bootstrap';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth, storage } from '../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, Timestamp, doc, getDoc, updateDoc, onSnapshot, getDocs } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';

const AddMoney = () => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState('Payment Tokens');
  const [walletInfo, setWalletInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWalletInfo = async () => {
      const walletCollection = collection(db, 'wallet');
      const walletSnapshot = await getDocs(walletCollection);
      const walletData = {};
      walletSnapshot.forEach(doc => {
        walletData[doc.id] = doc.data();
      });
      setWalletInfo(walletData);
    };

    fetchWalletInfo();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const refs = doc(db, 'users', user.uid);
          const docSnap = await getDoc(refs);

          if (docSnap.exists()) {
            const depositData = {
              amount: parseFloat(formData.amount),
              date: Timestamp.now(),
              status: 'Pending',
            };

            const storageRef = ref(storage, `DepositImage/${Date.now()}`);

            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
              'state_changed',
              () => {},
              (error) => {
                console.log(error);
              },
              async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                depositData.imgUrl = downloadURL;

                const depositRef = await addDoc(collection(db, 'users', user.uid, 'deposit'), depositData);

                const unsubscribe = onSnapshot(doc(db, 'users', user.uid, 'deposit', depositRef.id), (doc) => {
                  if (doc.exists() && doc.data().status === 'Successful') {
                    const newBalance = parseFloat(docSnap.data().balance) + parseFloat(formData.amount);
                    const newTotal = parseFloat(docSnap.data().total) + parseFloat(formData.amount);
                    updateDoc(refs, { balance: newBalance, totalDep: newTotal });
                    toast.success('Deposit successful. Balance updated.');
                    unsubscribe();
                  }
                });
              }
            );
          }
        }
      });

      setLoading(false);
      navigate('/dashboard');
      toast.info('Deposit pending, awaiting confirmation.');
    } catch (error) {
      setLoading(false);
      console.error('Error processing deposit: ', error);
      toast.error('Failed to process deposit.');
    }
  };

  return (
    <div className='bg-Primary-150 p-4'>
      <div className='pb-4'>
        <Link className='text-white' to='/dashboard'>
          <ArrowBackIcon />
        </Link>
      </div>
      <div className='border-1 border-slate-400 rounded p-4 flex items-center justify-between bg-Primary-250'>
        <h2 className='text-2xl text-slate-300 font-semibold'>Add Money</h2>
        <div>
          <Link to='/dashboard/transactions'>
            <button className='bg-red-500 text-slate-100 rounded p-2'>Transaction History</button>
          </Link>
        </div>
      </div>
      <div>
        <Form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 text-slate-200'>
            <FormGroup>
              <h3 className='text-lg py-2 font-semibold'>Payment Method</h3>
              <select className='w-100 bg-Primary-250 border-1 border-slate px-2 py-3' value={selectedOption} onChange={handleOptionChange} required>
                <option className='py-2' value="Payment Tokens">---Select Tokens---</option>
                <option className='py-2' value="BTC">BTC</option>
                <option className='py-2' value="ETH">ETH</option>
                <option className='py-2' value="USDT(TRC20)">USDT(TRC20)</option>
                <option className='py-2' value="USDT(ERC20)">USDT(ERC20)</option>
              </select>
            </FormGroup>
            <FormGroup>
              <h3 className='text-lg py-2 font-semibold'>Enter Amount</h3>
              <div className='border flex justify-between w-100 bg-Primary-250 border-1 border-slate pl-2'>
                <input id='amount' onChange={handleChange} required className="outline-none text-lg font-semibold py-3 w-full bg-Primary-250 h-full" type="text" placeholder='0.00' />
                <span className="bg-red-500 h-full font-semibold text-white px-3 rounded-l py-3">USD</span>
              </div>
            </FormGroup>
            {selectedOption !== 'Payment Tokens' && (
              <div className='w-full px-1'>
                <h3>Information for {selectedOption}:</h3>
                {walletInfo[selectedOption] && (
                  <div className='p-2'>
                    <p className='bg-red-500 p-3 text-white'>{walletInfo[selectedOption].information}</p>
                    <p>{selectedOption} wallet address:  </p>
                    <p className='pb-2 w-full text-sm  italic px-1 text-white'>{walletInfo[selectedOption].address}</p>
                    <p className=''>Or scan QR code</p>
                    <img className='fit-contain h-5/6 w-5/6' src={walletInfo[selectedOption].imageUrl} alt={selectedOption} />
                  </div>
                )}
                <FormGroup className='pt-4 w-full'>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="hidden"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <label htmlFor="file" className="cursor-pointer w-full">
                    <div className="p-8 rounded-lg border-1 border-slate-200 text-center">
                      {file ? (
                        <div>
                          <p className="text-gray-600">{file.name}</p>
                          <button onClick={() => setFile(null)} className="text-red-500 mt-2">Remove</button>
                        </div>
                      ) : (
                        <div>
                          <FileUploadIcon />
                          <p className="mt-2 text-gray-600">Upload File</p>
                        </div>
                      )}
                    </div>
                  </label>
                </FormGroup>
              </div>
            )}
          </div>
          <h2 className='py-4 text-slate-100 text-xl font-semibold'>Review Details</h2>
          <div className="border border-slate-400 flex items-center justify-between">
            <p className="text-lg font-semibol text-slate-400 py-3 pl-2">Amount</p>
            <p className="text-lg font-semibol text-slate-400 py-3 pr-5">{formData.amount}</p>
          </div>
          <div className="border border-slate-400 flex items-center justify-between">
            <p className="text-lg font-semibol text-slate-400 py-3 pl-2">Charge</p>
            <p className="text-lg font-semibol text-slate-400 py-3 pr-5">{formData.amount - formData.amount + 0}</p>
          </div>
          <div className="border border-slate-400 flex items-center justify-between">
            <p className="text-lg font-semibol text-slate-400 py-3 pl-2">Total</p>
            <p className="text-lg font-semibol text-slate-400 py-3 pr-5">{formData.amount}</p>
          </div>
          <div className='w-full md:w-1/2 lg:w-1/3'>
            <button disabled={loading} className='bg-red-600 w-full mt-4 text-white p-3 rounded-lg uppercase hover:bg-Primary-600 disabled:opacity-80'>
              {loading ? 'Processing...' : 'Proceed To Payment'}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddMoney;
