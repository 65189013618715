import React from 'react'
import ContactForm from '../Components/ContactForm'
import { Container } from 'react-bootstrap'
import contact from '../Assets/Images/contact.jpeg'
import PageShowcase from '../Components/Sections/PageShowcase'
import SupportCard from '../Components/SupportCard'
import support4 from '../Assets/Images/support4.jpeg'


const Contact = () => {
  return (
    <>
        <PageShowcase  bgImg={contact} header='Talk to our customer care providers' text='Please send us a secure message through our Contact us form. You can also contact our support Team using our support email or speak to an online Agent. Only contact us by phone if you urgently need our help.' btnText='Invest Now' link='/dashboard/invest'/>
        <Container className='grid grid-cols-1 md:grid-cols-2 py-5 px-1'>
            <SupportCard  img={support4} heading='Secure Message' text1='If you are registered and an Active Investor you can login and send us a secure message.' text2='If you have a question you can ask our customer care reps' text3='support@eastvestcapital.com'/>
            <ContactForm />
        </Container>
      
    </>
  )
}

export default Contact
