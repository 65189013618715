import {  useState } from 'react';
import { db } from '../firebase.config';
import { collection, doc, updateDoc, getDocs, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';


const UpdateWithdrawal = () => {
  const [withdrawData, setWithdrawData] = useState([]);
  
  
  const getData = async () => {
    try {
      const usersCollectionRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollectionRef);
      const withdrawPromises = usersSnapshot.docs.map(async (userDoc) => {
      const withdrawCollectionRef = collection(db, 'users', userDoc.id, 'withdraw');
      const withdrawSnapshot = await getDocs(withdrawCollectionRef);
      return withdrawSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, userId: userDoc.id }));
      });
      const withdrawDataList = await Promise.all(withdrawPromises);
      const flattenWithdrawData = withdrawDataList.flat();
      setWithdrawData(flattenWithdrawData);
    } catch (error) {
      console.error('Error fetching deposit data: ', error);
    }
  };
  getData()

  

  const updateWithdrawStatus = async (id, userId) => {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);
    try {
      await updateDoc(doc(db, 'users', userId, 'withdraw', id), {
        status: 'Success'
      });
      const withdrawToUpdate = withdrawData.find((withdraw) => withdraw.id === id);
      await updateDoc(userDocRef, {
        profit: userDocSnap.data().profit - withdrawToUpdate.amount,
        totalWit: userDocSnap.data().totalWit + withdrawToUpdate.amount
      });
      console.log('Withdrawal status updated to Success!');
    } catch (error) {
      console.error('Error updating withdrawal status: ', error);
    }
  };

  return (
    <div className= 'text-white'>
      
      <div className='flex py-5 px-3 bg-secondary-500 justify-around items-center md:w-1/2 m-auto'>
      <h2>Withdrawal Data</h2>
      <Link to='/dashboard/admin/update/deposit'><button className='p-3 rounded bg-green-800 text-white'>update Deposit</button></Link>
      </div>
      <div className='py-4 bg-secondary-500 px-2 md:w-1/2 m-auto' >
        {withdrawData.map((withdraw) => (
          <div className='flex justify-between items-center py-2' key={withdraw.id}>
            
            <span>{withdraw.amount}</span>
            {withdraw.status === 'Pending' && (
              <button className='bg-green-500 rounded p-2' onClick={() => updateWithdrawStatus(withdraw.id, withdraw.userId)}>Update Status</button>
            )}
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpdateWithdrawal;

/*
const deleteDeposit = async (id, userId) => {
  try {
    await deleteDoc(doc(db, 'users', userId, 'deposit', id));
    console.log('Document successfully deleted!');
  } catch (error) {
    console.error('Error deleting document: ', error);
  }
};

<button onClick={() => deleteDeposit(deposit.id, deposit.userId)}>Delete</button>
            {deposit.status === 'Success' && <span>Updated</span>} */