import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink} from 'react-router-dom';
import {Container} from 'react-bootstrap'
import './Header.css'
import { useState} from 'react';
import GoogleTranslateWidget from '../GoogleTranslate';
import SimpleBtn from '../Buttons/SimpleBtn';


const Header = () => {

  
  const [expanded, setExpanded] = useState(false)
  const normalLink = '';
  const activeLink = 'active'
  
  
  return (  
        <Navbar expanded={expanded} sticky='top' expand="lg"  className='my-nav  text-sm px-10'>
        <Container className='px-2 py-1 md:px-10'>
            
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : 'expanded')}  aria-controls="responsive-navbar-nav" className='d-flex d-lg-none flec-column justify-content-around'>
                <span className='toggler-icon top-bar'></span>
                <span className='toggler-icon mid-bar'></span>
                <span className='toggler-icon bottom-bar'></span>
            </Navbar.Toggle>
            
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="d-flex justify-content-around align-items-md-center gap-4">
             
                    <NavLink onClick={() => setExpanded(false)} className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item text-sm`)} to="/">Home</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item text-sm`)} to="/about-us">About Us</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item text-sm`)} to="/services">Services</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item text-sm`)} to="/dashboard">Investments</NavLink>
                    <NavDropdown className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item text-sm`)}  title="Support" id="collapsible-nav-dropdown">
                    <NavDropdown.Item><NavLink onClick={() => setExpanded(false)} to='support/contact'>Contact</NavLink></NavDropdown.Item>
                      <NavDropdown.Item><NavLink onClick={() => setExpanded(false)} to='/support/faq'>FAQ</NavLink></NavDropdown.Item>
                      
                 
                    </NavDropdown>
                    <GoogleTranslateWidget />
        </Nav>
        <Nav className='ms-auto d-flex justify-content-around align-items-md-center gap-4 '>
          <SimpleBtn link='/auth/register'  text='Register' classname=' bg-green-500 font-medium'/>
          <SimpleBtn  text='Invest Now' classname=' bg-Primary-500 font-medium' link='/dashboard/invest'/>
                  
        </Nav>
        </Navbar.Collapse>
        
        </Container>
        </Navbar>
  )
}

export default Header
