import React from 'react'

const Wallet1 = ({text, icon, bal}) => {
  return (
    <div className='flex justify-between text-white'>
      <div><span className='pl-1'>
        {icon}</span><span className='pl-1  text-base font-semibold'>{text}</span></div>
      <p className='pe-2 text-lg font-bold'>$ {bal}</p>
    </div>
  )
}

export default Wallet1
