import React, { useState } from 'react';
import { Col, Container, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase.config';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../Assets/Images/register.jpeg';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import logo from '../Assets/Images/logo.png';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (user.emailVerified) {
        setLoading(false);
        navigate('/dashboard');
        toast.success('Logged in successfully');
      } else {
        setLoading(false);
        auth.signOut();
        toast.error('Please verify your email before logging in.');
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Helmet>
        <title>Login</title>
        <meta  name='description' content='Login to your Eastvest dashboard to continue with our services.'/>
        <link rel="canonical" href="https://eastvestcapital.com/auth/login" />
      </Helmet>
      <Container>
        <div className='p-3 max-w-lg mx-auto'>
          {loading ? (
            <Col lg='12' className='text-center'>
              <Spinner animation='border' />
            </Col>
          ) : (
            <div lg={6} className='m-auto text-center'>
              <Link to='/'><div className=""><img src={logo} alt="footerLogo" /></div></Link>
              <h1 className='text-4xl text-center text-white font-semibold my-7'>
                Login
              </h1>
              <Form className='flex flex-col gap-4' onSubmit={handleLogin}>
                <label className='text-white text-start' htmlFor='email'>Email</label>
                <input
                  id='email'
                  className='border p-3 rounded-lg'
                  type='email'
                  placeholder='Enter Your Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className='text-white text-start' htmlFor='password'>Password</label>
                <input
                  id='password'
                  className='border p-3 rounded-lg'
                  type='password'
                  placeholder='Enter Your Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  disabled={loading}
                  className='bg-secondary-900 text-white p-3 rounded-lg uppercase hover:opacity-95 disabled:opacity-80'
                >
                  {loading ? 'Loading...' : 'Login'}
                </button>
                <p className='text-white font-semibold cursor-pointer'>
                  Don't have an account?{' '}
                  <Link to='/auth/register' className='text-blue-300'>Create an account</Link>
                </p>
              </Form>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Login;
