import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import logo from '../Assets/Images/logo.png'
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import { green} from '@mui/material/colors';
import LanguageIcon from '@mui/icons-material/Language';


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section className="bg-neutral-900 py-5">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-2 py-3">
          <div className="flex flex-col justify-start items-start">
            <div className=""><img src={logo} alt="footerLogo" /></div>
            <div className="flex gap-2 items-center justify-start py-2">
              <div className=""><PlaceIcon  style={{color: green[500]}}/></div>
              <p className="text-white">31 Walker Ave, Wolverton Mill, Wolverton, Milton Keynes MK12 5TW, UK</p>
            </div>
            <div className="flex gap-2 items-center justify-start py-2">
              <div className=""><EmailIcon  style={{color: green[500]}}/></div>
              <p className="text-white ">support@eastvestcapital.com</p>
            </div>
            <div className="flex gap-2 items-center justify-start py-2">
              <div className=""><LanguageIcon  style={{color: green[500]}}/></div>
              <p className="text-white ">https//:www.eastvestcapital.com</p>
            </div>
          </div>
          <div className="flex flex-col justify-start md:pl-5">
            <h5 className="text-Primary-500 text-2xl font-semibold  py-2 ">Services</h5>
            <div className="flex flex-col gap-2 text-white  pt-4">
              
              <Link to='/dashboard/invest'><p className=""><span className="text-Primary-500">&rarr;</span> Bronze Plan</p></Link>
              <Link to='/dashboard/invest'><p className=""><span className="text-Primary-500">&rarr;</span> Silver Plan</p></Link>
              <Link to='/dashboard/invest'><p className=""><span className="text-Primary-500">&rarr;</span> Gold plan</p></Link>
              <Link to='/dashboard/invest'><p className=""><span className="text-Primary-500">&rarr;</span> Others</p></Link>
            </div>
          </div>
          <div className="flex flex-col justify-start">
            <h5 className="text-Primary-500 text-2xl font-semibold  py-2 ">Quick Links</h5>
            <div className="flex flex-col gap-2 text-white  pt-4">
              <Link to='/auth/register'><p className=""><span className="text-Primary-500">&rarr;</span> Create Account</p></Link>
              <Link to='/auth/login'><p className=""><span className="text-Primary-500">&rarr;</span> Login</p></Link>
              <Link to='/dashboard/invest'><p className=""><span className="text-Primary-500">&rarr;</span> Invest</p></Link>
              <Link to='/support/contact'><p className=""><span className="text-Primary-500">&rarr;</span> Support</p></Link>
            </div>
          </div>
         
        </div>
        <p className="text-sm text-center text-white pt-3">&copy; {currentYear} Eastvest Capital. All Rights Reserved.</p>
      </Container>
    </section>
  )
}

export default Footer
