import {  useState } from 'react';
import { db } from '../firebase.config';
import { collection, doc, updateDoc, getDocs, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';


const UpdateData = () => {
  const [depositData, setDepositData] = useState([]);
  
  const getData = async () => {
    try {
      const usersCollectionRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollectionRef);
      const depositPromises = usersSnapshot.docs.map(async (userDoc) => {
      const depositCollectionRef = collection(db, 'users', userDoc.id, 'deposit');
      const depositSnapshot = await getDocs(depositCollectionRef);
      return depositSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, userId: userDoc.id }));
      });
      const depositDataList = await Promise.all(depositPromises);
      const flattenDepositData = depositDataList.flat();
      setDepositData(flattenDepositData);
    } catch (error) {
      console.error('Error fetching deposit data: ', error);
    }
  };
  getData()

  

  const updateDepositStatus = async (id, userId) => {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);
    try {
      await updateDoc(doc(db, 'users', userId, 'deposit', id), {
        status: 'Success'
      });
      const depositToUpdate = depositData.find((deposit) => deposit.id === id);
      await updateDoc(userDocRef, {
        balance: userDocSnap.data().balance + depositToUpdate.amount,
        totalDep: userDocSnap.data().totalDep + depositToUpdate.amount,
        status: 'Active'
      });
      console.log('Deposit status updated to Success!');
    } catch (error) {
      console.error('Error updating deposit status: ', error);
    }
  };

  return (
    <div className='text-white'>
      <div className='flex bg-secondary-500 px-3 py-5 justify-around items-center md:w-1/2 m-auto'>
      <h2>Deposit Data</h2>
      <Link to='/dashboard/admin/update/withdraw'><button className='p-3 rounded bg-green-800 text-white'>update Withdrwal</button></Link>
      </div>

      <div className='py-4 bg-secondary-500 px-3 md:w-1/2 m-auto'>
        {depositData.map((deposit) => (
          <div className='flex justify-between items-center py-2' key={deposit.id}>
            <img className='w-24 h-24 fit-contain' src={deposit.imgUrl} alt="" />
            <span>{deposit.amount}</span>
            {deposit.status === 'Pending' && (
              <button className='bg-green-600 p-2 rounded' onClick={() => updateDepositStatus(deposit.id, deposit.userId)}>Update Status</button>
            )}
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpdateData;

/*
const deleteDeposit = async (id, userId) => {
  try {
    await deleteDoc(doc(db, 'users', userId, 'deposit', id));
    console.log('Document successfully deleted!');
  } catch (error) {
    console.error('Error deleting document: ', error);
  }
};

<button onClick={() => deleteDeposit(deposit.id, deposit.userId)}>Delete</button>
            {deposit.status === 'Success' && <span>Updated</span>} */