import React from 'react'
import Header from '../Components/Header/Header'
import Router from '../Routers/Router'
import TopHeader from '../Components/Header/TopHeader'
import Footer from '../Components/Footer'
import { useLocation } from 'react-router-dom'




const Layout = () => {

  const location = useLocation()

  return (
    <>
      {
        location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/auth') ? '' :  <Header />
      }
      
      {
        location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/auth') ? '' :  <TopHeader />
      }
      <div className='overflow-hidden'>
      <Router />
      </div>
      {
        location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/auth') ? '' :  <Footer />
      }
      
    </>
  )
}

export default Layout
