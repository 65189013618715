import React from 'react'
import { Container } from 'react-bootstrap'
import './Header.css'
import logo from '../../Assets/Images/logo.png'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { green} from '@mui/material/colors';
import { Link } from 'react-router-dom';

const TopHeader = () => {
  return (
    <div className=" text-center text-secondary-500">
            <Container className='px-2'> 
                <div className='d-flex justify-content-between align-items-center py-2'>
                    <Link to='/'><img className='logo' src={logo} alt="brand pic" /></Link>
                    <div>
                        <Link to='/support/contact'><ContactMailIcon  fontSize='large' style={{color: green[600]}}/>
                        <p className='text-Primary-500 text-xl pl-2'>Contact Us</p></Link>
                    </div>
                </div>
                
            </Container>
        </div>
  )
}

export default TopHeader
