import React, { useState, useEffect, useRef } from 'react';
import { grey} from '@mui/material/colors';
import GroupIcon from '@mui/icons-material/Group';
import AddCardIcon from '@mui/icons-material/AddCard';
import IosShareIcon from '@mui/icons-material/IosShare';

const CounterSection = () => {
  const [activeUsers, setActiveUsers] = useState(0);
  const [deposits, setDeposits] = useState(0);
  const [withdrawals, setWithdrawals] = useState(0);

  // Ref to observe the section
  const sectionRef = useRef(null);

  // Function to handle intersection observer
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Perform counter animations when section is in view
        animateCounter(setActiveUsers, 1500, 652400); // Update active users to 150 after 1500ms
        animateCounter(setDeposits, 2000, 326);    // Update investors to 25 after 2000ms
        animateCounter(setWithdrawals, 2500, 125);   // Update profits to 50000 after 2500ms
      }
    });
  };

  // Effect to set up intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the section is in view
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // Function to animate counter from 0 to a target value
  const animateCounter = (setFunc, duration, targetValue) => {
    let start = 0;
    const increment = Math.ceil(targetValue / (duration / 10));

    const counter = setInterval(() => {
      start += increment;
      if (start >= targetValue) {
        clearInterval(counter);
        start = targetValue;
      }
      setFunc(start);
    }, 10);
  };

  return (
    <section className='bg-secondary-500 text-white rounded-sm p-10' ref={sectionRef}>
    
      <div className="statistics grid grid-cols-3 gap-2">
        <div className="statistic flex flex-col md:flex-row items-center justify-center gap-3 ">
          <div className='w-12 h-12 bg-green-500 rounded-sm flex items-center justify-center'><GroupIcon style={{color: grey[100]}} /></div>
          <div>
          <h3 className='text-center text-xl font-bold'>{activeUsers}</h3>
          <p className='text-center py-1'>Investors</p>
          </div>
        </div>
        <div className="statistic flex flex-col md:flex-row items-center justify-center gap-3 ">
          <div className='w-12 h-12 bg-green-500 rounded-sm flex items-center justify-center'><AddCardIcon style={{color: grey[100]}} /></div>
          <div>
          <h3 className='text-center text-xl font-bold'>${deposits}M</h3>
          <p className='text-center py-1'>Deposits</p>
          </div>
        </div>
        <div className="statistic flex flex-col md:flex-row items-center justify-center gap-3 ">
          <div className='w-12 h-12 bg-green-500 rounded-sm flex items-center justify-center'><IosShareIcon style={{color: grey[100]}} /></div>
          <div>
          <h3 className='text-center text-xl font-bold'>${withdrawals}M</h3>
          <p className='text-center py-2'>Withdrawals</p>
          
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;
