import React from 'react'
import SimpleBtn from './Buttons/SimpleBtn'
import list from '../Assets/Datas/list'

const PriceCard = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-3 '>
    {
      list.map((data, index) => (
      <div key={index} className={` rounded bg-white`}>
      <div className="level p-3 ">
        <h3 className='text-xl text-center text-Primary-500 font-semibold capitalize'>{data.level}</h3>
      </div>
      <div className={`percentage p-3 ${data.bg}`}>
        <h3 className='text-2xl text-center font-bold text-white'>{data.daily}%</h3>
        <p className='text-base text-center font-semibold capitalize text-green-700'>Daily income</p>
      </div>
      <div className="details p-3">
        <p className='text-center pb-2 font-semibold capitalize'>min Investment   <span className='text-green-700'>${data.min}</span></p>
        <p className='text-center pb-2 font-semibold capitalize'>max Investment  <span className='text-green-700'>${data.max}</span></p>
        <p className='text-center pb-2 font-semibold capitalize'>profit  <span className='text-green-700'>{data.Weekly}%</span></p>
        <p className='text-center pb-2 font-semibold capitalize'>Duration  <span className='text-green-700'>7 Days</span></p>
        <p className='text-center pb-2 font-semibold capitalize'>withdrawal or reinvestment After<span className='text-green-700'> 7 Days</span></p>  
      </div>
      <div className='flex align-middle justify-center p-4'><><SimpleBtn  text='Get Started' classname='bg-green-900 text-white uppercase font-semibold' link='/dashboard/invest'/></></div>
    </div>
      ))
    }
      
    </div>
    
  )
}

export default PriceCard
