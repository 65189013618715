const items = [
    {
        question: 'What is Eastvest Capital',
        answer: 'Eastvest Capitals is an innovative Investment platform established by a team of professional focusing mainly on cryptocurrency trading and real eastate. Investing in Eastvest Capital is a very good recommendation for those who are looking for a simple and reliable Investment platform with passive income'
    },
    {
      question: 'How do I create My Account?',
      answer: 'Registration process is very easy and will take a few moments to complete Simply click CREATE ACCOUNT button and fill in all the required fields and also upload KYC for verification.',
    },
   
    {
      question: 'How do i find company official wallet address for payment?',
      answer: 'You can find the company official wallet address after clicking on Make Deposit kindly copy carefully the wallet address you want to use for payment or contact our customer support for more information to get any wallet address of your choice',
    },
    {
      question: 'How many times can i make a deposit?',
      answer: 'Yes you can make as many deposit as you want on any of our investment plans except the bronze plan where you can only invest 5 times after which you make a choice to continue investing with us or not',
    },
    {
        question: 'How long does my deposit take before it can reflect?',
        answer: 'Your deposit will be reflected immediately once it is confirmed on the blockchain network.',
      },
    {
      question: 'How do I make a deposit?',
      answer: 'To deposit funds in your account is quick and simple For your convenience you may choose one of the several available deposit methods To make a successful deposit please follow the steps below Login to your account Click on the DEPOSITS button in the DASHBOARD section Choose the deposit option And follow the steps to complete your transaction.',
    },
    {
      question: 'How do I make a withdrawal?',
      answer: 'Login to your account DASHBOARD click on WITHDRAW Add Wallet Enter Amount Select Wallet and click on Make Withdrawal.',
    },
    {
      question: 'How long does it take to process My withdrawal?',
      answer: 'Once we receive your withdrawal request we process immediately and send to your provided wallet.',
    },
    {
      question: 'How long does it take my profit to reflect on my wallet?',
      answer: 'Your profits will reflect on your Eastvest profit wallet daily after you might have claimed it.',
    },
    {
      question: 'Can i reinvest from my profit balance?',
      answer: 'Yes Kindly follow the same process of investing but choose payment method as Profit Balance.',
    },
    {
      question: 'Can i have multiple accounts?',
      answer: 'We do not allow multiple accounts',
    },
    {
      question: 'Is my personal information secure?',
      answer: 'Yes, we prioritize the security of your personal information. We use advanced encryption technologies and follow strict data protection policies to ensure your data is safe.',
    },
  ];

  export default items