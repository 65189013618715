const items = [
    {
      question: 'How do I create My Account?',
      answer: 'Registration process is very easy and will take a few moments to complete Simply click CREATE ACCOUNT button and fill in all the required fields and also upload KYC for verification.',
    },
   
    {
      question: 'How many times can i make a deposit?',
      answer: 'Yes you can make as many deposit as you want on any of our investment plans except the bronze plan where you can only invest 5 times after which you make a choice to continue investing with us or not',
    },
    {
        question: 'How long does my deposit take before it can reflect?',
        answer: 'Your deposit will be reflected immediately once it is confirmed on the blockchain network.',
      },
    {
      question: 'How do I make a deposit?',
      answer: 'To deposit funds in your account is quick and simple For your convenience you may choose one of the several available deposit methods To make a successful deposit please follow the steps below Login to your account Click on the DEPOSITS button in the DASHBOARD section Choose the deposit option And follow the steps to complete your transaction.',
    },
    {
      question: 'How long does it take to process My withdrawal?',
      answer: 'Once we receive your withdrawal request we process immediately and send to your provided wallet.',
    },
  ];

  export default items