import React, { useEffect, useState } from 'react';

const GoogleTranslateWidget = () => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      // Define googleTranslateElementInit in the global scope
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          { pageLanguage: 'en' },
          'google_translate_element'
        );
      };

      // Create script element
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;

      script.addEventListener('load', () => setLoaded(true));
      script.addEventListener('error', handleScriptError);

      // Append script to the document body
      document.body.appendChild(script);

      // Cleanup function
      return () => {
        script.removeEventListener('load', () => setLoaded(false));
        script.removeEventListener('error', handleScriptError);

        // Remove script element from the document body
        document.body.removeChild(script);

        // Clean up global function
        delete window.googleTranslateElementInit;
      };
    } catch (error) {
      setError(error);
    }
  }, []); // Empty dependency array to run this effect only once on mount

  const handleScriptError = () => {
    setError(new Error('Error loading Google Translate script'));
  };

  return (
    <>
      {error ? (
        <div>G-Translate</div>
      ) : (
        loaded && <div id="google_translate_element" />
      )}
    </>
  );
};

export default GoogleTranslateWidget;
