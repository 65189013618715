import React, { useState, useEffect } from 'react'
import SimpleSlider from '../Components/Slider/Slider'
import data from '../Assets/Datas/data'
import About from '../Components/Sections/AboutUs'
import TradeView from '../Components/Tradeview/TradeView'
import Services from '../Components/Sections/Services'
import WhyUs from '../Components/Sections/WhyUs'
import TradingViewWidget from '../Components/Sections/NewsFeed'
import ToggleAccordion from '../Components/Sections/Faq'
import items from '../Assets/Datas/faq'
import testimonials from '../Assets/Datas/testimonials'
import TestimonialCarousel from '../Components/Sections/Testimonial'
import { Helmet } from "react-helmet-async"




const Home = () => {


  const [datas, setDatas] = useState([])

  useEffect(() => {
    const filteredData = data.filter(item => item.sect === 'heroSlider')

    setDatas(filteredData)
  }, [])
  return (
    <>   
      <Helmet>
        <title>Home</title>
        <meta  name='description' content='Eastvest Capital: A premier investment company, that specializes in leveraging cutting-edge technologies to analyze and trade cryptocurrencies, while also offering strategic investments in real estate.'/>
        <link rel="canonical" href="https://eastvestcapital.com/" />
      </Helmet>
      <SimpleSlider  slideData={datas}/>
      <About />
      <Services />
      <WhyUs />
      <TradeView />
      <ToggleAccordion items={items} />
      <TradingViewWidget />
      <div className='py-7'>
      <TestimonialCarousel testimonials={testimonials}/>
      </div>
      
    </>
  )
}

export default Home

