import React, { useState } from 'react';
import { FormGroup, Form } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../firebase.config';
import { collection, addDoc, Timestamp, doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';

const Invest = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const refs = doc(db, 'users', user.uid);
          const docSnap = await getDoc(refs);

          if (docSnap.exists()) {
            const amount = parseFloat(formData.amount);
            let interestRate = 0;

            switch (selectedOption) {
              case 'Bronze':
                interestRate = 0.065;
                break;
              case 'Silver':
                interestRate = 0.086;
                break;
              case 'Gold':
                interestRate = 0.110;
                break;
              default:
                break;
            }

            const walletBalance = wallet === 'main' ? docSnap.data().balance : docSnap.data().profit;
            if (walletBalance < amount) {
              alert('Insufficient balance in selected wallet.');
              setLoading(false);
              return;
            }

            let minimumBalance = 0;
            switch (selectedOption) {
              case 'Bronze':
                minimumBalance = 100;
                break;
              case 'Silver':
                minimumBalance = 1000;
                break;
              case 'Gold':
                minimumBalance = 10000;
                break;
              default:
                break;
            }

            if (walletBalance < minimumBalance || amount < minimumBalance) {
              alert(`Minimum balance and amount required is: ${minimumBalance}.`);
              setLoading(false);
              return;
            }

            const updateData = {};
            const updatedAmount = walletBalance - amount;
            updateData[wallet === 'main' ? 'balance' : 'profit'] = updatedAmount;
            await updateDoc(doc(db, 'users', user.uid), updateData);

            const investmentRef = await addDoc(collection(db, 'users', user.uid, 'investment'), {});
            const investmentId = investmentRef.id;

            const investment = {
              id: investmentId,
              amount: parseFloat(formData.amount),
              date: Timestamp.now(),
              plan: selectedOption,
              interestRate: interestRate,
              claimed: false,
              claims: 0,
              lastClaimDate: null,
            };

            
            const referrerId = docSnap.data().referrer;
            if (referrerId) {
              const referrerDoc = await getDoc(doc(db, 'users', referrerId));
              if (referrerDoc.exists()) {
                // Reward the referrer based on the investment
                // Add your reward logic here
                const referrerProfits = referrerDoc.data().balance || 0;
                const referralBonus = amount * 0.05; // 5% of the investment

                await updateDoc(doc(db, 'users', referrerId), {
                  balance: referrerProfits + referralBonus
                });
              }
            }
    
               
            await updateDoc(investmentRef, investment);

            navigate('/dashboard/notifications');
            
            
            
  
          }
        }
      });
    } catch (error) {
      console.log(error.message);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='bg-Primary-150 p-4'>
      <div className="lg:w-1/2 m-auto">
        <div className='pb-4'>
          <Link className='text-white' to='/dashboard'>
            <ArrowBackIcon />
          </Link>
        </div>
        <div className="border-1 border-slate-400 rounded p-4 flex items-center justify-between bg-Primary-250">
          <h2 className='text-2xl text-slate-300 font-semibold'>Review and Confirm Investment</h2>
        </div>
        <div className=''>
          <Form className='text-white mt-2' onSubmit={handleSubmit}>
            <FormGroup className='flex justify-between items-center px-2 py-3 border border-slate-50'>
              <h3 className='text-lg py-2 font-semibold w-full'>Investment plan</h3>
              <select className='w-100 bg-Primary-250 border-1 border-slate px-2 py-2' value={selectedOption} onChange={handleOptionChange} required>
                <option value="plans">---Select plan---</option>
                <option value="Bronze">Bronze</option>
                <option value="Silver">Silver</option>
                <option value="Gold">Gold</option>
              </select>
            </FormGroup>
            {selectedOption !== 'Plans' && (
              <div className=''>
                {selectedOption === 'Bronze' && (
                  <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                    <h3 className='text-lg py-2 font-semibold'>Amount</h3>
                    <p className='text-sm py-2 font-semibold'>Min of 100 - max of 999</p>
                  </div>
                )}
                {selectedOption === 'Silver' && (
                  <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                    <h3 className='text-lg py-2 font-semibold'>Amount</h3>
                    <p className='text-sm py-2 font-semibold'>Min of 1000 - max of 9999</p>
                  </div>
                )}
                {selectedOption === 'Gold' && (
                  <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                    <h3 className='text-lg py-2 font-semibold'>Amount</h3>
                    <p className='text-sm py-2 font-semibold'>Min of 10000 - max of 100000</p>
                  </div>
                )}
              </div>
            )}
            <FormGroup className='flex justify-between items-center px-2 py-3 border border-slate-50'>
              <h3 className='w-full text-lg py-2 font-semibold'>Enter Amount</h3>
              <input id='amount' onChange={handleChange} required className="w-100 border-1 border-slate px-2 py-2 text-lg font-semibold bg-Primary-250 h-full" type="text" placeholder='0.00' />
            </FormGroup>
            <FormGroup className='flex justify-between items-center px-2 py-3 border border-slate-50'>
              <h3 className='text-lg py-2 font-semibold w-full'>Investment plan</h3>
              <select className='w-100 bg-Primary-250 border-1 border-slate px-2 py-2' value={wallet} onChange={(e) => setWallet(e.target.value)} required>
                <option value="Wallet">---Choose wallet---</option>
                <option value="main">Main Wallet</option>
                <option value="profit">Profit Wallet</option>
              </select>
            </FormGroup>
            <FormGroup className='flex justify-between items-center px-2 py-3 border border-slate-50'></FormGroup>
            {selectedOption !== 'Plans' && (
              <div className=''>
                {selectedOption === 'Bronze' && (
                  <div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Interest</h3>
                      <p className='text-lg w-full py-2 font-semibold'>0.065% (Daily)</p>
                    </div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Capital Back</h3>
                      <p className='text-lg w-full py-2 font-semibold'>Yes</p>
                    </div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Total Investment Amount</h3>
                      <p className='text-lg w-full py-2 font-semibold'>00</p>
                    </div>
                  </div>
                )}
                {selectedOption === 'Silver' && (
                  <div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Interest</h3>
                      <p className='text-lg w-full py-2 font-semibold'>0.086% (Daily)</p>
                    </div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Capital Back</h3>
                      <p className='text-lg w-full py-2 font-semibold'>Yes</p>
                    </div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Total Investment Amount</h3>
                      <p className='text-lg w-full py-2 font-semibold'>00</p>
                    </div>
                  </div>
                )}
                {selectedOption === 'Gold' && (
                  <div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Interest</h3>
                      <p className='text-lg w-full py-2 font-semibold'>0.110% (Daily)</p>
                    </div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Capital Back</h3>
                      <p className='text-lg w-full py-2 font-semibold'>Yes</p>
                    </div>
                    <div className='flex justify-between items-center py-2 px-2 border border-slate-50'>
                      <h3 className='text-lg w-full py-2 font-semibold'>Total Investment Amount</h3>
                      <p className='text-lg w-full py-2 font-semibold'>00</p>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className='w-full md:w-1/2'>
              <button disabled={loading} className='bg-green-600 w-full mt-4 text-white p-3 rounded-lg uppercase hover:bg-Primary-600 disabled:opacity-80'>
                {loading ? 'Processing...' : 'Invest Now'}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Invest;
