import React from 'react'
import PageShowcase from '../Components/Sections/PageShowcase'
import service from '../Assets/Images/slider1.jpeg'
import { Container } from 'react-bootstrap'
import Headings from '../Components/Headings/Headings'
import Service from '../Components/Sections/Services'
import { Helmet } from 'react-helmet-async'


const Services = () => {
  return (
    <>
    <Helmet>
        <title>Services</title>
        <meta  name='description' content='we offer a comprehensive suite of services designed to empower you and enhance your quality of life'/>
        <link rel="canonical" href="https://eastvestcapital.com/services" />
      </Helmet>
      <PageShowcase  bgImg={service} header='Partner with Us to achieve your financial goals efficiently.' text='Eastvest Capital offers expert cryptocurrency trading and strategic real estate investments. we carefully select real estate opportunities for long-term growth. With our transparent investment packages, users can invest confidently, knowing that a percentage of profits will be added to their wallets daily.' btnText='Invest Now' link='/dashboard/invest'/>

      <Container className='py-5'>
                <div className='px-2 md:px-5 py-2 md-py-4'>
                    <Headings header2='What We Offer' headerP='At Eastvest Capital, we understand the importance of planning for retirement, and our dedicated team is here to guide you through the process. Whether you are just starting to build your retirement nest egg or looking to optimize your existing portfolio, our retirement planning services are tailored to meet your unique needs and goals.In addition to retirement planning, Eastvest Capital provides access to financing solutions through our loan services. Whether you are seeking capital to fund a new venture or consolidate existing debt, our flexible loan options are designed to support your financial aspirations.
                    ' headerP2='Furthermore, our expertise extends to real estate investment, offering opportunities for our investors to diversify their portfolios and capitalize on the lucrative real estate market. From residential properties to commercial ventures, we help our clients identify and invest in properties with high growth potential.

                    Finally, at Eastvest Capital, we specialize in cryptocurrency trading, leveraging advanced technology and market insights to maximize returns for our investors in the ever-evolving crypto landscape. Our strategic approach to crypto trading ensures that our clients can navigate this exciting market with confidence and clarity.
                    
                    By offering these services, Eastvest Capital aims to provide our investors with the tools and resources they need to achieve financial security and prosperity, ultimately leading to a better quality of life. Partner with us today and embark on a journey towards a brighter financial future with Eastvest Capital.' header1='As a leading investment company, we offer a comprehensive suite of services designed to empower our investors and enhance their quality of life.' claas1='text-secondary-500'/>
                </div> <br />
                
                <Service />
               
        </Container> 

    </>
  )
}

export default Services
