const list = [
    {
        bg: 'bg-Primary-100',
        level: 'Bronze',
        daily: '0.065',
        min: '500',
        max: '4,999',
        Weekly: '45',
    },
    {
        bg: 'bg-Primary-300',
        level: 'Silver',
        daily: '0.086',
        min: '5,000',
        max: '9,999',
        Weekly: '60',
    },
    {
        bg: 'bg-Primary-200',
        level: 'Gold',
        daily: '0.110',
        min: '10,000',
        max: '100,000',
        Weekly: '75',
    },

]

export default list;