import React from 'react'
import { Container } from 'react-bootstrap'
import Headings from '../Headings/Headings'
import SimpleBtn from '../Buttons/SimpleBtn'
import { Link } from 'react-router-dom'


const About= () => {
  return (
    <>
        <Container className='py-4'>
                <div className='px-2 md:px-5 py-2 md-py-3'>
                    <Headings header2='Who We Are' headerP='Welcome to Eastvest Capital, where innovation meets investment excellence. As a premier investment company, Eastvest Capital specializes in leveraging cutting-edge technologies to analyze and trade cryptocurrencies, while also offering strategic investments in real estate.
                    At Eastvest Capital, we pride ourselves on our team of expert traders who navigate the dynamic world of cryptocurrency markets. Our traders utilize sophisticated algorithms and data analytics to make informed decisions, maximizing profits and minimizing risks for our valued investors.
                    ' header1='Empowering Your Financial Future with Eastvest Capital' claas1='text-secondary-500'/>
                </div>
                <Link to='/about'><SimpleBtn  text='View More  &rarr;' classname='px-2 md:px-5 text-Primary-500 font-semibold'/></Link>
        </Container> 
    </>
  )
}

export default About
