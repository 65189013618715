import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Transaction = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const getData = async () => {
          try {
            const ref = doc(db, 'users', user.uid);
            const docSnap = await getDoc(ref);
            if (docSnap.exists()) {
              setCurrentUser(docSnap.data());
            }

            const transactionCol1 = collection(db, 'users', user.uid, 'deposit');
            const transactionCol2 = collection(db, 'users', user.uid, 'withdraw');
            const [querySnapshot1, querySnapshot2] = await Promise.all([
              getDocs(transactionCol1),
              getDocs(transactionCol2),
            ]);

            const fetchedTransactions = [];

            querySnapshot1.forEach((doc) => {
              fetchedTransactions.push({
                id: doc.id,
                type: 'Deposit',
                ...doc.data(),
              });
            });

            querySnapshot2.forEach((doc) => {
              fetchedTransactions.push({
                id: doc.id,
                type: 'Withdraw',
                ...doc.data(),
              });
            });

            fetchedTransactions.sort((a, b) => b.date.seconds - a.date.seconds); // Sort by date in descending order

            setTransactions(fetchedTransactions);
            setLoading(false);
          } catch (error) {
            console.log(error.message);
            setLoading(false);
          }
        };
        getData();
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <section className='bg-white pb-5 pt-3 px-1 overflow-hidden'>
      <Container className='bg-green-50 p-2'>
        <div className='py-3 mt-2 self-center px-2'>
        <div className='pb-4'><Link className='text-slate-500' to='/dashboard'><ArrowBackIcon /></Link></div>
          <h3 className='text-center text-xl py-2'>Transaction History</h3>
          {loading ? (
            <p>Loading...</p>
          ) : (
            transactions.map((data) => (
              <div
                className='flex flex-col mb-1 p-2 border rounded-md bg-green-500 text-white'
                key={data.id}
              >
                <div className='flex justify-between'>
                  <p className='text-sm'>
                    {data.type}
                  </p>
                  <p className='font-semi-bold'>${data.amount}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-sm'>
                    {new Date(data.date.seconds * 1000).toLocaleString()}
                  </p>
                  <p className='text-sm'>{data.status}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </Container>
    </section>
  );
};

export default Transaction;
