import React from 'react'
import { Link } from 'react-router-dom'

const WalletBtn = ({classname, text, icon, link}) => {
  return (
    <button className={` ${classname}   py-2 w-full font-semibold text-white`} ><span className='pe-1'>{icon}</span><Link to={link}>{text}</Link></button>
  )
}

export default WalletBtn
