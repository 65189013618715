import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home'
import AboutUs from '../Pages/AboutUs'
import Services from '../Pages/Services'
import ProtectedRouts from './ProtectedRouts'
import Dashboard from '../Pages/Dashboard'
import SignUp from '../Pages/SignUp'
import SignIn from '../Pages/SignIn'
import UpdateData from '../Pages/UpdateData'
import AddMoney from '../Pages/AddMoney'
import Withdraw from '../Pages/Withdraw'
import Settings from '../Pages/Settings'
import Notifications from '../Pages/Notifications'
import UpdateWithdrawal from '../Pages/UpdateWithdrawal'
import Invest from '../Pages/Invest'
import Transaction from '../Pages/Transaction'
import UpdateWalletInfo from '../Pages/Wallets'
import Contact from '../Pages/Contact'
import Faq from '../Pages/Faq'
import TermsAndConditions from '../Pages/TermsAndConditions'



const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />}/>
      <Route path='/about-us' element={<AboutUs />}/>
      <Route path='/services' element={<Services />}/>
      <Route path='/support/contact' element={<Contact />}/>
      <Route path='/support/faq' element={<Faq />}/>
      <Route path='/auth/register' element={<SignUp />}/>
      <Route path='/auth/login' element={<SignIn /> }/> 
      <Route path='/terms-and-conditions' element={<TermsAndConditions /> }/> 
      <Route path='/*' element={ <ProtectedRouts /> }>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='dashboard/addmoney' element={<AddMoney />} />
        <Route path='dashboard/withdraw' element={<Withdraw />} />
        <Route path='dashboard/settings' element={<Settings />} />
        <Route path='dashboard/transactions' element={<Transaction/>} />
        <Route path='dashboard/invest' element={<Invest />} />
        <Route path='dashboard/notifications' element={<Notifications />} />
        <Route path='dashboard/admin/update/deposit' element={<UpdateData /> }/>
        <Route path='dashboard/admin/update/withdraw' element={<UpdateWithdrawal />}/>
        <Route path='dashboard/admin/update/wallet' element={<UpdateWalletInfo />}/>
        
      </Route>
    </Routes>
  )
}

export default Router
