import React from 'react'

const DashBox = ({text, value,sign,icon}) => {
  return (
    <>
      <div className='flex items-center justify-around bg-Primary-900 rounded p-3'>
                <div className="w-full">
                <div className="bg-white w-12 h-12 border border-yellow-500 rounded-full flex items-center justify-center">{icon}</div>
                </div>
                <div className='w-full'>
                    <div className="text-lg text-white" ><p className='pl-1'>{sign} {value}</p></div>
                    <p className="text-base text-white">{text}</p>

                </div>
            </div>
    </>
  )
}

export default DashBox
