import React from 'react'
import teamData from '../Assets/Datas/team'

const Team = () => {
  return (
    <>
        <h2 className='text-3xl text-center py-4 font-bold'>Meet the Board</h2>
        <div className='grid grid-cols-1'>
            {
                teamData.map((data, index) =>(
                    <div key={index} className='flex gap-4 flex-col md:flex-row py-5'>
                    <div className=' pl-3'><img className='w-full  object-contain' src={data.img} alt="team" /></div>
                    <div className='w-full'>
                        <p className='py-2 font-bold text-xl'>{data.name}</p>
                        <p className='py-2 font-bold text-xl'>{data.post}</p>
                        <p className='py-2 text-base'>{data.about}</p>
                    </div>
                    </div>
                ))
            }
        </div>
      
    </>
  )
}

export default Team
