import React, { useState } from 'react';
import { FormGroup, Form } from 'react-bootstrap';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../firebase.config';
import { collection, addDoc, Timestamp, doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';

const Withdraw = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Payment Gateway');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const refs = doc(db, 'users', user.uid);
          const docSnap = await getDoc(refs);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const withdrawAmount = parseFloat(formData.amount);

            if (withdrawAmount <= parseFloat(userData.profit)) {
              // Withdraw data
              const withdrawData = {
                amount: withdrawAmount,
                address: formData.address,
                date: Timestamp.now(),
                status: 'Pending', // Set the status to Pending initially
              };

              // Add the withdraw data to Firestore
              const withdrawRef = await addDoc(collection(db, 'users', user.uid, 'withdraw'), withdrawData);

              toast.info('Withdraw pending, awaiting confirmation.');

              // Update the balance only if the withdraw status changes to 'Successful'
              const unsubscribe = onSnapshot(doc(db, 'users', user.uid, 'withdraw', withdrawRef.id), (doc) => {
                if (doc.exists() && doc.data().status === 'Successful') {
                  // Update the balance with the withdrawn amount
                  const newBalance = parseFloat(userData.profit) - withdrawAmount;
                  const newTotal = parseFloat(userData.totalWit) + withdrawAmount;
                  updateDoc(refs, { profit: newBalance, totalWit: newTotal });
                  toast.success('Withdraw successful. Balance updated.');
                  unsubscribe(); // Stop listening for changes once the balance is updated
                }
              });
            } else {
              toast.error('Insufficient funds in your Profit wallet.');
            }
          }
        }
      });

      setLoading(false);
      navigate('/dashboard');
    
    } catch (error) {
      setLoading(false);
      toast.error('Error processing the withdraw.');
      console.error(error.message);
    }
  };

  return (
    <div className='bg-Primary-150 p-4'>
      <div className='pb-4'><Link className='text-white' to='/dashboard'><ArrowBackIcon /></Link></div>
      <div className="border-1 border-slate-400 rounded p-4 flex items-center justify-between bg-Primary-250">
        <h2 className='text-2xl text-slate-300 font-semibold'>Withdraw Money</h2>
        <div><Link to='/dashboard/transactions'><button className='bg-red-500 text-slate-100 rounded p-2'>Transaction History</button></Link></div>
      </div>
      <div>
        <Form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 text-slate-200'>
            <FormGroup>
              <h3 className='text-lg py-2 font-semibold'>Withdraw Method</h3>
              <select className='w-100 bg-Primary-250 border-1 border-slate px-2 py-3' value={selectedOption} onChange={handleOptionChange} required>
                <option value="Payment Gateway">---Select Token---</option>
                <option value="BTC">BTC</option>
                <option value="ETH">ETH</option>
                <option value="USDT">USDT</option>
              </select>
            </FormGroup>
            <FormGroup>
              <h3 className='text-lg py-2 font-semibold'>Enter Amount</h3>
              <div className='border flex justify-between w-100 bg-Primary-250 border-1 border-slate pl-2'>
                <input id='amount' onChange={handleChange} required className="outline-none text-lg font-semibold w-full py-3 bg-Primary-250 h-full" type="text" placeholder='0.00' />
                <span className="bg-red-500 h-full font-semibold text-white px-3 rounded-l py-3">USD</span>
              </div>
            </FormGroup>
            {selectedOption !== 'Payment Gateway' && (
              <div className='w-full'>
                <h3>Information for {selectedOption}:</h3>
                {selectedOption === 'BTC' && (
                  <div className='w-full'>
                    <h3 className='text-lg py-2 font-semibold'>Wallet Address</h3>
                    <input id='address' onChange={handleChange} required className="bg-Primary-250 border border-slate pl-2 text-lg font-semibold py-3 w-full" type="text" placeholder='Enter BTC Wallet Address' />
                  </div>
                )}
                {selectedOption === 'ETH' && (
                  <div className='w-full'>
                    <h3 className='text-lg py-2 font-semibold'>Wallet Address</h3>
                    <input id='address' onChange={handleChange} required className="bg-Primary-250 border border-slate pl-2 text-lg font-semibold py-3 w-full" type="text" placeholder='Enter ETH Wallet Address' />
                  </div>
                )}
                {selectedOption === 'USDT' && (
                  <div className='w-full'>
                    <h3 className='text-lg py-2 font-semibold'>Wallet Address</h3>
                    <input id='address' onChange={handleChange} required className="bg-Primary-250 border border-slate pl-2 text-lg font-semibold py-3 w-full" type="text" placeholder='Enter USDT Wallet Address' />
                  </div>
                )}
              </div>
            )}
          </div>
          <h2 className='py-4 text-slate-100 text-xl font-semibold'>Withdraw Details</h2>
          <div className="border border-slate-400 flex items-center justify-between">
            <p className="text-lg font-semibol text-slate-400 py-3 pl-2">Amount</p>
            <p className="text-lg font-semibol text-slate-400 py-3 pr-5">{formData.amount}</p>
          </div>
          <div className="border border-slate-400 flex items-center justify-between">
            <p className="text-lg font-semibol text-slate-400 py-3 pl-2">Charge</p>
            <p className="text-lg font-semibol text-slate-400 py-3 pr-5">{parseFloat(formData.amount) - parseFloat(formData.amount)}</p>
          </div>
          <div className="border border-slate-400 flex items-center justify-between">
            <p className="text-lg font-semibol text-slate-400 py-3 pl-2">Total</p>
            <p className="text-lg font-semibol text-slate-400 py-3 pr-5">{formData.amount}</p>
          </div>
          <div className='w-full md:w-1/2 lg:w-1/3'>
            <button disabled={loading} className='bg-red-600 w-full mt-4 text-white p-3 rounded-lg uppercase hover:bg-Primary-600 disabled:opacity-80'>
              {loading ? 'Processing...' : 'Withdraw Money'}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Withdraw;
