import React, { useEffect, useState } from 'react'
import logo from '../Assets/Images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import Wallet1 from '../Components/Wallet1'
import WalletBtn from '../Components/Buttons/WalletBtn';
import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddTaskIcon from '@mui/icons-material/AddTask';
import dashboardData from '../Assets/Datas/dashboardData';
import DashboardView from './DashboardView';
import AddMoney from './AddMoney';
import Withdraw from './Withdraw';
import Transaction from './Transaction';
import Settings from './Settings';
import Notifications from './Notifications';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { Spinner } from 'react-bootstrap';
// import GoogleTranslate from '../Components/GoogleTranslate'

const Dashboard = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});
  const [deposit, setDeposit] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [investment, setInvestment] = useState([]);
  const [loading, setLoading] = useState('');

  useEffect(() => {
    setLoading(true);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const getData = async () => {
          try {
            const ref = doc(db, 'users', user.uid);
            const docSnap = await getDoc(ref);
            if (docSnap.exists()) {
              setCurrentUser(docSnap.data());
            }

            const depositCol = collection(db, 'users', user.uid, 'deposit');
            const depositSnapshot = await getDocs(depositCol);
            const fetcheDeposit = [];

            const withdrawCol = collection(db, 'users', user.uid, 'withdraw');
            const withdrawSnapshot = await getDocs(withdrawCol);
            const fetcheWithdraw = [];

            const investmentCol = collection(db, 'users', user.uid, 'investment');
            const investmentSnapshot = await getDocs(investmentCol);
            const fetcheinvestment = [];

            depositSnapshot.forEach((doc) => {
              fetcheDeposit.push({
                id: doc.id,
                ...doc.data()
              });
            });
            withdrawSnapshot.forEach((doc) => {
              fetcheWithdraw.push({
                id: doc.id,
                ...doc.data()
              });
            });
            investmentSnapshot.forEach((doc) => {
              fetcheinvestment.push({
                id: doc.id,
                ...doc.data()
              });
            });
            setDeposit(fetcheDeposit);
            setInvestment(fetcheinvestment);
            setWithdraw(fetcheWithdraw);

            setLoading(false);
          } catch (error) {
            console.log(error.message);
          }
        };
        getData();
      }
    });
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <DashboardView len={deposit.length + withdraw.length} value={currentUser.balance} stat={currentUser.status} totalDep={currentUser.totalDep} totalWit={currentUser.totalWit} investLen={investment.length} totalProfit={currentUser.totalProfit} totalReferral={currentUser.referralCount} />; // Replace with your actual component
      case 2:
        return <AddMoney />; // Replace with your actual component
      case 3:
        return <Withdraw /> // Replace with your actual component
      case 4:
        return <Transaction /> // Replace with your actual component
      case 5:
        return <Notifications />// Replace with your actual component
      default:
        return <Settings />// Default content
    }
  };

  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId === activeTab ? 1 : tabId);
  };
  const activeClass = 'text-left rounded-full py-2 w-full pl-3 hover:bg-red-400 bg-Primary-900 transition text-white text-base font-semibold uppercase';
  const normalClass = 'text-left rounded-full py-2 w-full pl-3 hover:bg-red-400 transition text-slate-600 text-base font-semibold uppercase';

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <section className=''>
      {
        loading ? (
          <div className='h-screen flex justify-center items-center'>
            <Spinner animation="border" />
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className='bg-Primary-700 shadow-md'>
                <div className="flex items-center shadow-md md:bg-Primary-700 justify-center p-2">
                  <Link to='/'><img src={logo} alt="dashboard" /></Link>
                </div>
                <div className='shadow-md py-2'>
                  <div className="p-3">
                    <div className='px-3 pt-3 gap-2 bg-Primary-800 p-2 rounded-md flex flex-col'>
                      <div className='flex items-center justify-between '>
                        <button className='bg-white rounded-md self-start px-2'>Wallet</button>
                        <h2 className='text-white text-xl font-semibold p-1'>Account Balance</h2>
                      </div>
                      <Wallet1 icon={<AccountBalanceIcon />} text='Main Wallet' bal={currentUser.balance} />
                      <Wallet1 icon={<AccountBalanceWalletIcon />} text='Profit wallet' bal={currentUser.profit} />
                    </div>
                    <div className='flex gap-3 pt-3'>
                      <WalletBtn link='/dashboard/addmoney' icon={<AddCardIcon />} text='Deposit' classname='bg-Primary-600 rounded-md px-2 hover:bg-red-500 transition' />
                      <WalletBtn link='/dashboard/invest' icon={<AddTaskIcon />} text='Invest Now' classname='bg-Primary-50 rounded-md px-2 hover:bg-red-500 transition' />
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-1 px-3 py-3'>
                  {
                    dashboardData.map((info, index) => (
                      <button onClick={() => handleTabClick(info.id)} key={index}
                        className={info.id === activeTab ? activeClass : normalClass}> {info.icon}  {info.text}</button>
                    ))
                  }
                  <button onClick={handleLogout} className='bg-red-700 rounded-full py-2 w-full px-3 hover:bg-red-400 transition text-center text-white text-base font-semibold uppercase'>Logout</button>
                </div>
              </div>
              <div id='dash' className="lg:col-span-2">
                {renderContent()}
              </div>
            </div>
          </div>
        )
      }
    </section>
  );
}

export default Dashboard;
