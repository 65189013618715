import React, { useEffect, useRef, useState, memo } from 'react';
import './Tradingview.css';

function TradingViewWidget() {
  const containerRef = useRef(null);
  const scriptRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const handleScriptError = () => {
    console.log('Error loading script');
    // Handle the error here gracefully, e.g., display a message to the user
  };

  useEffect(() => {
    try {
      const container = containerRef.current;
      if (!container) return;

      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;

      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "BINANCE:BTCUSDT",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;

      script.onload = () => {
        setScriptLoaded(true);
      };
      script.addEventListener('error', handleScriptError);

      container.appendChild(script);
      scriptRef.current = script;

      return () => {
        const scriptToRemove = scriptRef.current;
        const containerToRemove = container;

        if (scriptToRemove && containerToRemove && containerToRemove.contains(scriptToRemove)) {
          containerToRemove.removeChild(scriptToRemove);
          scriptRef.current = null;
        }
      };
    } catch (error) {
      console.log(error.message);
      // Handle the error here gracefully, e.g., display a message to the user
    }
  }, []);

  return (
    <div className="tradingview-widget-container" ref={containerRef}>
      {!scriptLoaded && <div className="loading-placeholder">Loading...</div>}
      {scriptLoaded && <div className="tradingview-widget-container__widget"></div>}
      <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
    </div>
  );
}

export default memo(TradingViewWidget);
