import React from 'react'
import medal from '../Assets/Images/medal.png'
import CopyInput from '../Components/CopyText'
import DashBox from '../Components/DashBox'
import AddCardIcon from '@mui/icons-material/AddCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import TaskIcon from '@mui/icons-material/Task';
import IosShareIcon from '@mui/icons-material/IosShare';
import GroupsIcon from '@mui/icons-material/Groups';


const DashboardView = ({len, stat, totalDep, investLen, totalWit, totalProfit, totalReferral}) => {
    
  return (
    <section className='bg-slate-100 w-full px-4'>
        <div className='grid grid-cold-1 w-full gap-5 md:grid-cols-4 pt-5'>
            <div className="relative w-44 h-44 md:w-32 md:h-32   lg:w-44 lg:h-44 border-4 bg-secondary-500 border-yellow-500 rounded-full flex items-center justify-center">
            {/* Award icon */}
                <p className='text-center text-lg text-white font-bold'>{stat} <br /> <span className=' text-sm text-Primary-400'>Eastvest Member</span></p>
                
                <div className="absolute top-8 right-2 transform translate-x-1/2 -translate-y-1/2 bg-Primary-50 border-yellow-500 border-4 w-16 h-16 rounded-full flex items-center justify-center">
                <img className='fit-contain w-1/2 h-1/2' src={medal} alt="" />
                </div>

            </div>
            <div className="col-span-3 md:ml-5  bg-white border w-full border-slate-200 rounded-sm">
                <h2 className='py-3 px-3 text-lg font-semibold border-b-2'>Referral Code</h2>
                <div className="px-4 py-2">
                <div className="w-full"><CopyInput /></div>
                <p className='pt-2 pl-1 text-slate-600 text-sm'>{totalReferral || 0} {
                totalReferral <= 1 ? 'person' : 'people' } have joined by using this code</p>
                </div>
            </div>
        </div>
        <div className="grid pt-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-5 ">
            <DashBox text='All Transaction' value={len} icon={<ReceiptIcon />}/>
            <DashBox text='Total Deposit' value={totalDep} sign='$' icon={<AddCardIcon />}/>
            <DashBox text='Total Profit' value={totalProfit} sign='$' icon={<CardGiftcardIcon/>}/>
            <DashBox text='Total Investment' value={investLen} icon={<TaskIcon/>}/>
            <DashBox text='Total Withdrwal' value={totalWit} sign='$' icon={<IosShareIcon/>}/>
            <DashBox text='Total Referral' icon={<GroupsIcon/>} value={totalReferral}/>
            
        </div>
    </section>
  )
}

export default DashboardView
