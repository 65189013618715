
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCardIcon from '@mui/icons-material/AddCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import IosShareIcon from '@mui/icons-material/IosShare';

const dashboardData = [
    {
        icon: <DashboardIcon />,
        id: 1,
        link: 'dashboard',
        text: 'dashboard'
    },
    {
        icon: <AddCardIcon />,
        id: 2,
        link: 'deposit',
        text: 'add money'
    },
    {   icon: <IosShareIcon />,
        id: 3,
        link: 'withdraw',
        text: 'withdraw'
    },
    {
        icon: <ReceiptIcon />,
        id: 4,
        link: 'transactions',
        text: 'all transactions'
    },
    {
        icon: <ReceiptLongIcon />,
        id: 5,
        link: 'notifications',
        text: 'Get profit'
    },
    {
        icon: <SettingsIcon />,
        id: 6,
        link: 'settings',
        text: 'settings'
    },
    
]

export default dashboardData