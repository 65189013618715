import React, { useState, useEffect } from 'react';
import { FormGroup, Form, Button } from 'react-bootstrap';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { db, storage } from '../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const UpdateWalletInfo = () => {
  const [walletInfo, setWalletInfo] = useState({});
  const [selectedWallet, setSelectedWallet] = useState('');
  const [formData, setFormData] = useState({});
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchWalletInfo = async () => {
      const walletCollection = collection(db, 'wallet');
      const walletSnapshot = await getDocs(walletCollection);
      const walletData = {};
      walletSnapshot.forEach(doc => {
        walletData[doc.id] = doc.data();
      });
      setWalletInfo(walletData);
    };

    fetchWalletInfo();
  }, []);

  const handleWalletChange = (event) => {
    setSelectedWallet(event.target.value);
    setFormData(walletInfo[event.target.value] || {});
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let imageUrl = formData.imageUrl;

      if (file) {
        const storageRef = ref(storage, `WalletImages/${Date.now()}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        const downloadURL = await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            (error) => {
              console.error(error);
              reject(error);
            },
            async () => {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(url);
            }
          );
        });

        imageUrl = downloadURL;
      }

      const walletDocRef = doc(db, 'wallet', selectedWallet);
      await updateDoc(walletDocRef, {
        ...formData,
        imageUrl,
      });

      toast.success('Wallet information updated successfully.');
      setLoading(false);
    } catch (error) {
      console.error('Error updating wallet information:', error);
      toast.error('Failed to update wallet information.');
      setLoading(false);
    }
  };

  return (
    <div className='bg-Primary-150 p-4'>
      <h2 className='text-2xl text-slate-300 font-semibold'>Update Wallet Information</h2>
      <Form onSubmit={handleSubmit}>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 text-slate-200'>
          <FormGroup>
            <h3 className='text-lg py-2 font-semibold'>Select Wallet</h3>
            <select className='w-100 bg-Primary-250 border-1 border-slate px-2 py-3' value={selectedWallet} onChange={handleWalletChange} required>
              <option value="">---Select Wallet---</option>
              <option value="BTC">BTC</option>
              <option value="ETH">ETH</option>
              <option value="USDT(TRC20)">USDT(TRC20)</option>
              <option value="USDT(ERC20)">USDT(ERC20)</option>
            </select>
          </FormGroup>

          {selectedWallet && (
            <>
              <FormGroup>
                <h3 className='text-lg py-2 font-semibold'>Wallet Information</h3>
                <textarea
                  id='information'
                  value={formData.information || ''}
                  onChange={handleChange}
                  required
                  className='w-100 bg-Primary-250 border-1 border-slate px-2 py-3'
                  rows='5'
                />
              </FormGroup>
              <FormGroup>
                <h3 className='text-lg py-2 font-semibold'>Wallet address</h3>
                <input
                  id='address'
                  value={formData.address || ''}
                  onChange={handleChange}
                  required
                  className='w-100 bg-Primary-250 border-1 border-slate px-2 py-3'
                />
              </FormGroup>
              <FormGroup className='pt-4 w-full'>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="hidden"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <label htmlFor="file" className="cursor-pointer w-full">
                  <div className="p-8 rounded-lg border-1 border-slate-200 text-center">
                    {file ? (
                      <div>
                        <p className="text-gray-600">{file.name}</p>
                        <button onClick={() => setFile(null)} className="text-red-500 mt-2">Remove</button>
                      </div>
                    ) : (
                      <div>
                        <FileUploadIcon />
                        <p className="mt-2 text-gray-600">Upload File</p>
                      </div>
                    )}
                  </div>
                </label>
              </FormGroup>
            </>
          )}
        </div>

        {selectedWallet && (
          <div className='w-full md:w-1/2 lg:w-1/3'>
            <Button disabled={loading} type='submit' className='bg-red-600 w-full mt-4 text-white p-3 rounded-lg uppercase hover:bg-Primary-600 disabled:opacity-80'>
              {loading ? 'Updating...' : 'Update Wallet'}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default UpdateWalletInfo;
