import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';

const TradingViewWidget = () => {
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef(null);
  const scriptRef = useRef(null);

  useEffect(() => {
    try {
      const container = containerRef.current;
      if (!container) return;

      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
      script.async = true;

      script.addEventListener('load', () => {
        setLoaded(true);
      });

      script.addEventListener('error', handleScriptError);

      script.innerHTML = JSON.stringify({
        "feedMode": "all_symbols",
        "isTransparent": false,
        "displayMode": "regular",
        "width": "100%",
        "height": "50%",
        "colorTheme": "light",
        "locale": "en"
      });

      // Append the script to the body
      container.appendChild(script);
      scriptRef.current = script;

      return () => {
        const scriptToRemove = scriptRef.current;
        const containerToRemove = container;

        if (scriptToRemove && containerToRemove && containerToRemove.contains(scriptToRemove)) {
          containerToRemove.removeChild(scriptToRemove);
          scriptRef.current = null;
        }
      };
    } catch (error) {
      console.log('Error in useEffect:', error.message);
    }
  }, []);

  const handleScriptError = () => {
    console.log('Error loading script');
    // Handle the script error here, e.g., set a flag to indicate error
  };

  return (
    <Container className="tradingview-widget-container h-0" ref={containerRef}>
      {!loaded && <div className="loading-placeholder">Loading...</div>}
      {loaded && <div className="tradingview-widget-container__widget "></div>}
      
    </Container>
  );
};

export default TradingViewWidget;
