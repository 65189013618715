import React, { useRef, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth'
import {auth, db} from '../firebase.config'
import {doc, getDoc} from 'firebase/firestore'

const CopyInput = () => {
    const inputRef = useRef(null);
    const [userData, setUserData] = useState('');
    useEffect(()=>{
        
          onAuthStateChanged(auth, (user) => {
            
            if (user) {
              const getData = async()=>{
                try {
                  const ref = doc(db, 'users', user.uid);
                  const docSnap = await getDoc(ref);
                  if (docSnap.exists()) {
                    setUserData(docSnap.data())
                  }
                  
                }  catch (error) {
                  console.log(error.message)
                }     
              }
              getData()
              
            }
          })
      }, [])


    const handleCopyText = () => {
        // Select the text inside the input field
        inputRef.current.select();
        // Copy the selected text to the clipboard
        document.execCommand('copy');
    };

    return (
        <div className='border flex justify-between w-full border-gray-300 rounded-md'>
            <input
                ref={inputRef}
                type="text"
                placeholder="Enter text"
                className="outline-none py-2 pl-1 text-lg font-semibold"
                value={userData.referral} // Use defaultValue instead of value
                readOnly // Make the input field read-only
            />
            <span onClick={handleCopyText} className="bg-red-500 text-sm h-full font-semibold text-white px-2 rounded-md py-2">Copy Text</span>
        </div>
    );
};

export default CopyInput;
