import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../firebase.config';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Notifications = () => {
  const [investments, setInvestments] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        await updateAndLoadInvestments(user.uid);
      } else {
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  const updateAndLoadInvestments = async (userId) => {
    try {
      const investmentCollection = collection(db, 'users', userId, 'investment');
      const investmentSnapshot = await getDocs(investmentCollection);
      const updatedInvestments = investmentSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      const currentDate = new Date();

      for (let i = 0; i < updatedInvestments.length; i++) {
        const investmentData = updatedInvestments[i];
        const investmentDocRef = doc(db, 'users', userId, 'investment', investmentData.id);
        const investmentDocSnap = await getDoc(investmentDocRef);

        if (investmentDocSnap.exists()) {
          const investmentDocData = investmentDocSnap.data();
          const lastClaimDate = investmentDocData.lastClaimDate ? investmentDocData.lastClaimDate.toDate() : null;

          if (investmentDocData.claimed && lastClaimDate && ((currentDate - lastClaimDate) >= (24 * 60 * 60 * 1000)) && investmentDocData.claims < 7) {
            await updateDoc(investmentDocRef, { claimed: false });
            updatedInvestments[i].claimed = false;
          }
        }
      }
      setInvestments(updatedInvestments);
    } catch (error) {
      console.error('Error loading investments:', error);
    }
  };

  const handleClaimProfit = async (id, index) => {
    if (!user) return;

    try {
      const userRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userRef);

      if (userDocSnap.exists()) {
        const investmentDocRef = doc(db, 'users', user.uid, 'investment', id);
        const investmentDocSnap = await getDoc(investmentDocRef);

        if (investmentDocSnap.exists()) {
          const investmentData = investmentDocSnap.data();
          const { claimed, claims, amount, interestRate, lastClaimDate } = investmentData;

          const currentDate = new Date();
          const lastClaim = lastClaimDate ? lastClaimDate.toDate() : null;
          const canClaim = !lastClaim || ((currentDate - lastClaim) >= (24 * 60 * 60 * 1000));

          if (canClaim && !claimed && claims < 7) {
            const newClaims = claims + 1;
            const profit = amount * interestRate;
            let updatedProfit = userDocSnap.data().profit + profit;
            let updatedTotalProfit = userDocSnap.data().totalProfit + profit;

            if (newClaims === 7) {
              updatedProfit += amount; // Add the investment amount to the profit balance
              updatedTotalProfit += amount;
            }

            await updateDoc(investmentDocRef, {
              claimed: true,
              claims: newClaims,
              lastClaimDate: currentDate,
            });

            await updateDoc(userRef, { profit: updatedProfit, totalProfit: updatedTotalProfit });

            const updatedInvestments = [...investments];
            updatedInvestments[index] = { ...investmentData, claimed: true, claims: newClaims, lastClaimDate: currentDate };
            setInvestments(updatedInvestments);
          } else {
            alert('You cannot claim profit from this investment now.');
          }
        } else {
          alert('Investment not found.');
        }
      }
    } catch (error) {
      console.error('Error claiming profit:', error);
    }
  };

  return (
    <div className='bg-Primary-150 p-4 h-full text-white'>
      <div className="lg:w-1/2 m-auto">
        <div className='pb-4'>
          <Link className='text-white' to='/dashboard'>
            <ArrowBackIcon />
          </Link>
        </div>
        <div>
          <h2 className='text-2xl text-slate-300 font-semibold'>Investment Claims</h2>
          {investments.map((investment, index) => (
            <div key={investment.id} className='flex justify-between items-center py-2 px-2 border border-slate-50'>
              <div>
                <h3 className='text-lg py-2 font-semibold'>{investment.plan} Plan</h3>
                <p className='text-sm py-2 font-semibold'>Amount: {investment.amount}</p>
                <p className='text-sm py-2 font-semibold'>Interest Rate: {investment.interestRate * 100}% (Daily)</p>
              </div>
              {investment.claims < 7 ? (
                <button
                  onClick={() => handleClaimProfit(investment.id, index)}
                  className={`p-2 rounded-lg uppercase ${
                    investment.claimed ? 'bg-gray-400 text-gray-600 cursor-not-allowed' : 'bg-green-600 text-white hover:bg-Primary-600'
                  }`}
                  disabled={investment.claimed}
                >
                  {investment.claimed ? 'Claimed' : 'Claim Profit'}
                </button>
              ) : (
                <span className='text-red-500'>All Claims Used</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
