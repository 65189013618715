import slide1 from '../Images/slider1.jpeg'
import slide2 from '../Images/slider2.jpeg'
import slide3 from '../Images/slider3.jpeg'



const data = [
    {
        bgImg: slide1,
        header1: 'Welcome to Eastvest Capital: your pathway to financial success',
        header2: "Unleash Your Potential",
        bodyText: "Embark on a journey towards financial success with Eastvest Capital. Discover personalized strategies tailored to amplify your wealth and secure your financial goals.",
        sect: 'heroSlider'
    },
    {
        bgImg: slide2,
        header1: 'Let our experts and technology maximize your profits',
        header2: 'Expertise Meets Innovation',
        bodyText: 'Our team of seasoned professionals leverages cutting-edge technology to optimize your investment portfolio. Trust us to navigate the market and deliver exceptional returns.',
        sect: 'heroSlider'
    },
    {
        bgImg: slide3,
        header1: 'Take bold steps to change your world',
        header2: 'Empower Your Future',
        bodyText: 'At Eastvest Capital, we believe in taking decisive actions to shape your financial future. Explore investment opportunities that pave the way for meaningful change',
        sect: 'heroSlider'
    },
    
]

export default data