import React, { useState, useEffect, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db, storage } from '../firebase.config';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Container } from 'react-bootstrap';
import { Button, Spinner } from 'react-bootstrap';

const Settings = () => {
  const [user, setUser] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setLoading(true);
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          // Convert Firestore Timestamp to Date string
          if (data.dateOfBirth && data.dateOfBirth instanceof Timestamp) {
            data.dateOfBirth = data.dateOfBirth.toDate().toISOString().split('T')[0];
          }
          setProfileData(data);
        }
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSave = async () => {
    if (!user) return;
    setSaving(true);

    let avatarURL = profileData.avatarURL || '';

    if (avatar) {
      const avatarRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(avatarRef, avatar);
      avatarURL = await getDownloadURL(avatarRef);
    }

    const updatedProfileData = {
      ...profileData,
      photoURL: avatarURL,
      name: profileData.name || '',
      email: profileData.email || '',
      phone: profileData.phone || '',
      address: profileData.address || '',
      gender: profileData.gender || '',
      // Convert Date string back to Firestore Timestamp
      dateOfBirth: profileData.dateOfBirth ? Timestamp.fromDate(new Date(profileData.dateOfBirth)) : null,
    };

    try {
      await updateDoc(doc(db, 'users', user.uid), updatedProfileData);
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile: ', error);
      alert('Failed to update profile');
    } finally {
      setSaving(false);
    }
  };

  return (
    <section className='bg-white pb-5 pt-3 px-1 overflow-hidden'>
      <Container className='bg-green-50 p-2'>
        <div className='py-3 mt-2 self-center px-2'>
          <h3 className='text-center text-xl py-2'>Profile Settings</h3>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div className=''>
              <div className='flex flex-col items-center relative'>
                <img
                  src={profileData.photoURL || 'https://via.placeholder.com/150'}
                  alt="Avatar"
                  className='w-32 h-32 rounded-full mb-4 cursor-pointer'
                  onClick={handleImageClick}
                />
                <input
                  type='file'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <div className='absolute -bottom-6 text-center'>
                <span className='text-sm'>↑</span>
                  <p className='text-sm font-semibold'>Update Profile Picture</p>
                  
                </div>
              </div>
              <div className='grid grid-cols-1 pt-5 md:grid-cols-2 gap-4'>
                <div className='flex flex-col'>
                  <label className='text-sm font-semibold'>Name</label>
                  <input
                    type='text'
                    className='border p-2 mb-4'
                    value={profileData.name || ''}
                    onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
                  />
                </div>
                <div className="flex flex-col">
                  <label className='text-sm font-semibold'>Email</label>
                  <input
                    type='email'
                    className='border p-2 mb-4'
                    value={profileData.email || ''}
                    readOnly
                  />
                </div>
                <div className="flex flex-col">
                  <label className='text-sm font-semibold'>Phone</label>
                  <input
                    type='text'
                    className='border p-2 mb-4'
                    value={profileData.phone || ''}
                    onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
                  />
                </div>
                <div className="flex flex-col">
                  <label className='text-sm font-semibold'>Address</label>
                  <input
                    type='text'
                    className='border p-2 mb-4'
                    value={profileData.address || ''}
                    onChange={(e) => setProfileData({ ...profileData, address: e.target.value })}
                  />
                </div>
                <div className="flex flex-col">
                  <label className='text-sm font-semibold'>Gender</label>
                  <select
                    className='border p-2 mb-4'
                    value={profileData.gender || ''}
                    onChange={(e) => setProfileData({ ...profileData, gender: e.target.value })}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <label className='text-sm font-semibold'>Date of Birth</label>
                  <input
                    type='date'
                    className='border p-2 mb-4'
                    value={profileData.dateOfBirth || ''}
                    onChange={(e) => setProfileData({ ...profileData, dateOfBirth: e.target.value })}
                  />
                </div>
              </div>
              <Button
                variant="primary"
                onClick={handleSave}
                disabled={saving}
              >
                {saving ? 'Saving...' : 'Save Changes'}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Settings;
