import React from 'react'

const SupportCard = ({img, heading, text1, text2, text3,tel}) => {
  return (
    <div>
      <img className='w-20 h-20 ' src={img} alt="support" />
      <div className='text-secondary-500 px-5'>
        <h3 className='font-bold text-2xl py-2 '>{heading}</h3>
        <p className='text-base py-2'>{text1} <br /> <br /><span text-bold>{tel}</span> {text2} <br /><br />{text3}</p>
      </div>
    </div>
  )
}

export default SupportCard
