import React from 'react'
import { Container } from 'react-bootstrap'
import Headings from '../Headings/Headings'
import PriceCard from '../PriceCard'



const Service = () => {
  return (
    <div className='bg-secondary-500'>
        <Container className='py-5 '>
            <div className='px-2 md:px-5 py-2 md-py-4 text-white'>
                <Headings header2='What We Offer' headerP='At Eastvest Capital, our approach is rooted in research, analysis, and a deep understanding of market dynamics. We prioritize transparency and integrity in every investment decision. From asset management to strategic planning, we provide comprehensive solutions that optimize returns and mitigate risks.'  header1='Explore a range of investment services designed to meet your unique needs.' claas2='text-white' claas1='text-secondary-700'/>
            </div>
            <PriceCard />
        </Container>
    </div>
  )
}

export default Service
