import React from 'react'
import './Headings.css'

const Headings = ({header2, headerP, headerP2, header1, claas2, claas1}) => {
  return (
    <div className=' px-2'>
        <div className="flex justify-content-center align-items-center">
        <h2 className={` pb-2 text-uppercase text-center text-Primary-500 text-base font-semibold header2 ${claas2}`}>{header2}</h2>
        </div>
        <h3 className={` text-center text-capitalise font-bold text-2xl py-2 ${claas1}`}>{header1}</h3>
      <p className=' text-base md:text-center py-2'>{headerP}</p>  <br /> 
      <p className=' text-base '>{headerP2}</p>  
    </div>
  )
}

export default Headings
